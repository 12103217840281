require('domready')(function () {
  var scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  document.addEventListener('click', function (event) {
    if (event.target.matches('a') &&
        event.target.hash.length > 0 &&
        event.target.hash != '#0') {

      event.preventDefault();

      var target = document.querySelector('a[name="' + event.target.hash.substring(1) + '"]');
      var mainHeaderHeight = document.querySelector('.main-header').clientHeight;
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      var targetTop = scrollTop + target.getBoundingClientRect().top - mainHeaderHeight;

      window.scrollTo({
        top: targetTop,
        left: 0,
        behavior: 'smooth'
      });
    }
  });
});
