// Ignore clicks on #0 anchors
require('domready')(function () {
  var anchorTarget = '#0';

  document.body.addEventListener('click', (e) => {
    if (e.target.hash && e.target.hash == anchorTarget) {
      e.preventDefault();
    }
  });
})
