class parallaxBackground {
  constructor (elem, backgroundSelector) {
    this.elem = elem;
    this.backgroundElem = elem.querySelector(backgroundSelector);
    this.lastScrollTop = null;
    this.init();
  }

  static viewportHeight() {
    return window.innerHeight || document.documentElement.clientHeight;
  }

  static scrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  init() {
    this.update();

    this.backgroundElem.addEventListener('load', this.render.bind(this))
    window.addEventListener('scroll', this.render.bind(this));
  }

  // Element's bounding box
  bounding() {
    return this.elem.getBoundingClientRect();
  }

  // Check if element is inside the browser window (even partially)
  isInViewport() {
    return this.bounding().bottom >= 0 &&
           this.bounding().top <= this.constructor.viewportHeight();
  }

  viewportScrollRatio() {
    var bounding = this.bounding(),
        ratio = bounding.top / this.constructor.viewportHeight();

    return ratio;
  }

  elemToBackgroundHeightRatio() {
    return this.backgroundElem.clientHeight / this.elem.clientHeight;
  }

  render() {
    requestAnimationFrame(this.update.bind(this))
  }

  update() {
    if (this.isInViewport()) {
      percent = 100 * this.viewportScrollRatio() * (this.elemToBackgroundHeightRatio() - 1);
      this.backgroundElem.style.top = -percent + '%';
    }
  }
}

require('domready')(function () {
  var parallaxElements = document.querySelectorAll('.article-background');

  // Initialize all parallax backgrounds
  Array.from(parallaxElements).forEach(elem => {
    new parallaxBackground(elem.closest('article'), '.article-background');
  });

  new parallaxBackground(document.querySelector('.hero'), 'img');
});
