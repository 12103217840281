// Ignore clicks on #0 anchors
require('domready')(function () {
  if (document.body.id != 'contact') {
    return;
  }

  var scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
  var contactForm = document.querySelector('.contact-form form');
  var formFields = contactForm.querySelectorAll('.form-control');
  var submitButton = document.querySelector('.contact-form .btn-submit');
  var successContainer = document.querySelector('.contact-form .success');
  var messageDuration = 7000;

  var displaySuccess = function() {
    updateSubmitButton('sent');
    successContainer.classList.add('active');
    setTimeout(resetForm, messageDuration)
  }

  var resetForm = function() {
    contactForm.reset();
    updateSubmitButton('default');
    document.activeElement.blur();
    successContainer.classList.remove('active');
  }

  var getFormData = function() {
    return new FormData(contactForm);
  }

  var clearFormErrors = function() {
    Array.from(formFields).forEach(formField => {
      formField.classList.remove('error');
    });
  }

  var displayFormErrors = function(data) {
    var errorFields = Object.keys(data);
    var minTop = null;
    var fieldTop;
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var mainHeaderHeight = document.querySelector('.main-header').clientHeight;

    Array.from(errorFields).forEach(formFieldName => {
      formField = document.getElementById('message_' + formFieldName);
      formField.classList.add('error');

      // Calculate offset for scrolling
      fieldTop = scrollTop + formField.getBoundingClientRect().top - mainHeaderHeight;

      if (minTop == null || fieldTop < minTop) {
        console.log(formField);
        minTop = fieldTop;
      }
    });

    // Scroll to first error field on mobile devices
    if (window.innerWidth <= 768) {
      window.scrollTo({
        top: minTop,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  var updateSubmitButton = function(label, disabled = false) {
    submitButton.innerText = submitButton.dataset['text' + label.charAt(0).toUpperCase() + label.slice(1)];
    submitButton.disabled = disabled;
  }

  var submitForm = function() {
    clearFormErrors();
    updateSubmitButton('sending', true);

    var request = new XMLHttpRequest();
    request.open('POST', contactForm.action, true);

    request.onload = function() {
      updateSubmitButton('default');

      if (request.status >= 200 && request.status < 400) {
        displaySuccess();
      } else if (request.status >= 500) {
        alert('An error occurred while submitting the form')
      } else {
        data = JSON.parse(request.response);
        displayFormErrors(data);
      }
    };

    request.onerror = function() {
      alert('An error occurred while submitting the form')
    };

    request.send(getFormData());
  }

  submitButton.addEventListener('click', (e) => {
    e.preventDefault();
    submitForm();
  });

  Array.from(formFields).forEach(formField => {
    formField.addEventListener('change', (e) => {
      formField.classList.remove('error');
    });
  });
})
