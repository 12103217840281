require('domready')(function () {
  var hamburgerButton = document.querySelector('button.hamburger');
  var mainNav = document.querySelector('.main-nav');
  var mainNavTopLinks = mainNav.querySelectorAll('.main-nav li.has-children > a');
  var mainNavBackLinks = mainNav.querySelectorAll('.main-nav a.close-sub-nav');
  var subNavs = mainNav.querySelectorAll('.sub-nav');
  var mainHeader = document.querySelector('.main-header');
  var mainHeaderBackgroundTriggerElements = mainHeader.querySelectorAll('.main-nav li.has-children, .main-nav .sub-nav, .main-nav .sub-nav *');
  var mainHeaderOffsetTop;

  // Toggle navigation panel upon clicking on hamburger
  hamburgerButton.addEventListener('click', () => {
    if (hamburgerButton.classList.contains('is-active')) {
      hamburgerButton.classList.remove('is-active');
      mainNav.classList.remove('active', 'open');
    } else {
      hamburgerButton.classList.add('is-active');
      mainNav.classList.add('active');
    }
  });

  // Handle subnav opening
  Array.from(mainNavTopLinks).forEach(link => {
    link.addEventListener('click', (e) => {
      Array.from(subNavs).forEach(subNav => {
        subNav.classList.remove('active');
      });

      link.nextSibling.classList.add('active');
      mainNav.classList.add('open');
    })
  });

  // Handle subnav closing
  Array.from(mainNavBackLinks).forEach(link => {
    link.addEventListener('click', (e) => {
      mainNav.classList.remove('open');
    });
  })

  // Make header sticky after scrolling past it
  var resetHeaderOffsetTop = function() {
    mainHeaderOffsetTop = mainHeader.offsetTop;
  }

  var checkHeaderFixed = function() {
    if (window.pageYOffset > mainHeaderOffsetTop) {
      mainHeader.classList.add('fixed');
    } else {
      mainHeader.classList.remove('fixed');
    }
  }

  resetHeaderOffsetTop();
  checkHeaderFixed();

  // Add active classes to header when interacting with dropdowns
  triggerElements = Array.from(mainHeaderBackgroundTriggerElements);

  mainNav.addEventListener('mouseover', (e) => {
    if(triggerElements.indexOf(e.target) > -1) {
      mainHeader.classList.add('active');
    }
  });

  mainNav.addEventListener('mouseout', (e) => {
    if(triggerElements.indexOf(e.target) > -1) {
      mainHeader.classList.remove('active');
    }
  });

  // Window resize functions
  window.addEventListener('resize', resetHeaderOffsetTop);

  // Window scroll functions
  window.addEventListener('scroll', checkHeaderFixed);

});
